import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cx from 'clsx';

// Components
import Layout from 'components/layout';

// Images
import FlatPulseIcon from 'images/svg/flat-pulse.svg';
import SuperPulseIcon from 'images/svg/super-pulse.svg';
import DublePulseIcon from 'images/svg/duble-pulse.svg';
import QuadroPulseIcon from 'images/svg/quadro-pulse.svg';
import DoubleSpeedScanIcon from 'images/svg/double-speed-scan.svg';

// Styles
import * as styles from './highly-customized-treatment.module.scss';

function CustomizedTreatmentPage() {
  const description = 'Reverso is a Fractional RF device that incorporates several innovative technological solutions, all intended to guarantee optimal clinical results skin resurfacing, tissue remodeling, stretch mark treatment and anti-aging treatment.';

  return (
    <Layout title="Reverso by INDIBA | Deep Fractional Radiofrequency for Skin Aging Treatment" meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.pngg' }}>
      <div className={styles.Wrapper}>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h1>Highly Customized Treatment</h1>
            <h4>Tailored to Patients&apos; Needs</h4>
            <p>
              The
              <strong> tips used </strong>
              for treatment by Reverso, the
              <strong> profiles of the electric pulses </strong>
              emitted
              through these tips, and the
              <strong> patterns of energy distribution </strong>
              across the needles on the
              tip can all be tailored by Reverso users,
              to match the patient’s specific needs, including:
            </p>
            <ul>
              <li>Skin type</li>
              <li>Skin Condition</li>
              <li>Clinical Indications</li>
              <li>Treatment area</li>
              <li>Individual sensitivity</li>
            </ul>
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h3 className={styles.Subtitle}>Interchangeable Tips</h3>
            <p>Reverso offers several interchangeable tips, for a highly customized treatment:</p>
          </div>
          <div className={styles.Image}>
            <StaticImage src="../../images/tips.png" className={styles.ImageTips} alt="Tips" />
            <div className={styles.ImageTipsVertical}>
              <StaticImage src="../../images/tips-vertical.png" alt="Tips" />
            </div>
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h3 className={styles.Subtitle}>VPP - Variable Pulse Profile</h3>
            <p>
              Treatment by Reverso is based on the Variable Pulse Profile (VPP) technology.
              The user-friendly touch screen allows users to select the RF pulse shape,
              based on patients’ overall situation:
            </p>
            <div className={styles.Table}>
              <div className={styles.TableChild}>
                <FlatPulseIcon />
              </div>
              <div className={styles.TableChild}>
                <p>
                  <strong>Flat Pulse</strong>
                </p>
              </div>
              <div className={cx(styles.TableChild, styles.TableChildText)}>
                <p>
                  Constant energy throughout the pulse duration.
                  Suitable for all skin types and skin conditions.
                </p>
              </div>
              <div className={styles.Border} />
              <div className={styles.TableChild}>
                <SuperPulseIcon />
              </div>
              <div className={styles.TableChild}>
                <p>
                  <strong>Super Pulse</strong>
                </p>
              </div>
              <div className={cx(styles.TableChild, styles.TableChildText)}>
                <p>
                  Peak-then-plateau pulse, for deeper ablation.
                  Suitable for repairing scars (including post acne scars) and stretch marks,
                  as well as for massive tissue remodeling in mature skin.
                </p>
              </div>
              <div className={styles.Border} />
              <div className={styles.TableChild}>
                <DublePulseIcon />
              </div>
              <div className={styles.TableChild}>
                <p>
                  <strong>
                    Duble Pulse
                  </strong>
                </p>
              </div>
              <div className={cx(styles.TableChild, styles.TableChildText)}>
                <p>
                  Double pulse in varying power, for extra ablation and coagulation.
                  Suitable for treatment of mature skin and for repairing scars.
                </p>
              </div>
              <div className={styles.Border} />
              <div className={styles.TableChild}>
                <QuadroPulseIcon />
              </div>
              <div className={styles.TableChild}>
                <p>
                  <strong>Quadro Pulse</strong>
                </p>
              </div>
              <div className={cx(styles.TableChild, styles.TableChildText)}>
                <p>
                  Multiple lower-energy pulses in each time unit.
                  Especially suitable for sensitive skin and for the more delicate facial areas.
                </p>
              </div>
              <div className={styles.Border} />
              <div className={styles.TableChild}>
                <DoubleSpeedScanIcon />
              </div>
              <div className={styles.TableChild}>
                <p>
                  <strong>Double Speed Scan</strong>
                </p>
              </div>
              <div className={cx(styles.TableChild, styles.TableChildText)}>
                <p>
                  Parallel pulse, enabling quick energy transmission,
                  in half the time of the Flat Pulse. Suitable for broad skin areas,
                  as well as for thin and sensitive skin (less-painful treatment).
                </p>
              </div>
              <div className={styles.Border} />
            </div>
          </div>
        </div>
        <div className={cx(styles.Block, styles.EnergyPatternsBlock)}>
          <div className={styles.Text}>
            <h3 className={styles.Subtitle}>Energy Delivery Patterns</h3>
            <p>
              Relying on the ability to control energy delivery to each pin on its tip individually,
              Reverso offers its users selection between
              {' '}
              <strong>
                multiple energy delivery patterns,
                each suitable for specific treatment objectives.
                {' '}
              </strong>
              E.g.:
            </p>
            <div className={styles.EnergyPatternsGridSection}>
              <div className={styles.Flex}>
                <StaticImage width={536} height={360} src="../../images/full-grid.png" alt="Full Grid" className={styles.ImageEnergyPatterns} />
                <div className={styles.SecondaryText}>
                  <h5>Full Grid</h5>
                  <p>
                    100% of the pins are active - This pattern generates maximum ablation spots
                    in the treatment area. Intended for rejuvenation of mature skin.
                  </p>
                </div>
              </div>
              <div className={styles.Flex}>
                <StaticImage width={536} height={360} src="../../images/partial-grid.png" alt="Partial Grid" className={cx(styles.ImageEnergyPatterns, styles.ImagePartialGrid)} />
                <div className={styles.SecondaryText}>
                  <h5>Partial Grid</h5>
                  <p>
                    50% of the pins are active - This energy delivery pattern is most suitable for
                    resurfacing younger skin and for shrinking enlarged pores.
                  </p>
                </div>
              </div>
              <div className={styles.Flex}>
                <StaticImage width={536} height={360} src="../../images/alternating-lines.png" alt="Alternating Lines" className={cx(styles.ImageEnergyPatterns, styles.ImageAlternatingLines)} />
                <div className={cx(styles.SecondaryText, styles.AlternatingLinesText)}>
                  <h5>Alternating Lines</h5>
                  <p>
                    50% of the pins are active - This pattern is specifically intended for skin
                    tightening in a pre-defined direction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ImageBrami}>
        <StaticImage src="../../images/brami.jpeg" alt="" />
      </div>
    </Layout>
  );
}

export default CustomizedTreatmentPage;
