// extracted by mini-css-extract-plugin
export var AlternatingLinesText = "highly-customized-treatment-module--AlternatingLinesText--LLOT0";
export var Block = "highly-customized-treatment-module--Block---bFuS";
export var Border = "highly-customized-treatment-module--Border--MjVtJ";
export var EnergyPatternsBlock = "highly-customized-treatment-module--EnergyPatternsBlock--XJZQR";
export var EnergyPatternsGridSection = "highly-customized-treatment-module--EnergyPatternsGridSection--zFH-m";
export var Flex = "highly-customized-treatment-module--Flex--KvRuG";
export var Image = "highly-customized-treatment-module--Image--nZY4T";
export var ImageAlternatingLines = "highly-customized-treatment-module--ImageAlternatingLines--NSBUV";
export var ImageBrami = "highly-customized-treatment-module--ImageBrami--2tU2c";
export var ImageEnergyPatterns = "highly-customized-treatment-module--ImageEnergyPatterns--4Al2X";
export var ImagePartialGrid = "highly-customized-treatment-module--ImagePartialGrid--xzsFy";
export var ImageTips = "highly-customized-treatment-module--ImageTips--uE3y1";
export var ImageTipsVertical = "highly-customized-treatment-module--ImageTipsVertical--detpS";
export var SecondaryText = "highly-customized-treatment-module--SecondaryText--Ytc+a";
export var Subtitle = "highly-customized-treatment-module--Subtitle--jlZiP";
export var Table = "highly-customized-treatment-module--Table--pFL3h";
export var TableChild = "highly-customized-treatment-module--TableChild--i3vrX";
export var TableChildText = "highly-customized-treatment-module--TableChildText--EkxFj";
export var Text = "highly-customized-treatment-module--Text--s+gus";
export var Wrapper = "highly-customized-treatment-module--Wrapper--CKVWN";